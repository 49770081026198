import React, { useContext } from "react";
import { Box, Button, Typography } from "@mui/material";
import thankyou from "../images/thankyou.svg";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import { Context } from "../context";

const Thankyou = ({ catID }) => {
  const { formType, setFormType, name, setName } = useContext(Context);
  const navigate = useNavigate();
  return (
    <>
      <Header />

      <section id="banner">
        <div className="banner internal mpadding"></div>
      </section>
      <main>
        <section id="main">
          <div className="container">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                justifyContent: "center",
                alignItems: "center",
                // minHeight: "30vh",
                // m: "0px auto",
                // height: "90vh",
              }}
            >
              <Box
                height={{ sm: 150, md: 290 }}
                width={{ sm: 150, md: 290 }}
                mt={{ sm: "-20px" }}
              >
                <img src={thankyou} alt="thanks" />
              </Box>
              <Typography
                sx={{ fontSize: { md: "36px", sm: "24px", xs: "24px" } }}
              >
                {formType
                  ? "Thank you for successfully registering. Webinar details are shared over email and SMS"
                  : "Thank you Our Team will reach out to you very soon...!"}
              </Typography>
              <Button
                style={{
                  cursor: "pointer",
                  borderRadius: "35px",
                  color: "#FFF",
                  padding: "10px 20px",
                  background: "linear-gradient(80deg, #405cb2, #44b6a7)",
                }}
                onClick={() => navigate("/") && setFormType(false)}
              >
                Back to home
              </Button>
            </Box>
          </div>
        </section>
      </main>
    </>
  );
};

export default Thankyou;
