import React from "react";
import {
  GetQuates,
  getAllStock,
  getReportCategoryWithSubcategory,
} from "../../utils/APICollection";
import { Context } from "../../context";
import { CircularProgress, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackgroundLetterAvatars from "./avatar/LetterAvatar";
import Nostock from "../../images/stockn.webp";
import StockSlider from "./StockSlider";

const AllStocks = ({ isMobile }) => {
  const [recoStocksData, setRecoStockData] = React.useState([]);
  const [loadingStock, setLoadingStock] = React.useState(false);
  const { exchange, setResearchCategory } = React.useContext(Context);
  const navigate = useNavigate();

  const getAllCategory = async () => {
    const { data } = await getReportCategoryWithSubcategory();
    if (data?.status == "success") {
      localStorage.setItem("researchData", JSON.stringify(data?.data));
      setResearchCategory(data?.data);
    }
  };
  const getAllRecomStock = async () => {
    setLoadingStock(true);
    let finaldata = [];
    try {
      const { data } = await getAllStock();
      if (data?.status == "success") {
        const promises = data?.data?.map(async (obj) => {
          const { data } = await GetQuates(obj?.cmots_code, exchange);
          return {
            ...obj,
            change: data?.data?.[0].change,
            Price: data?.data?.[0].Price || data?.data?.data?.[0].price,
          };
        });
        finaldata = await Promise.all(promises);
        setRecoStockData(finaldata);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingStock(false);
    }
  };
  const selectedSearchItem = (co_code, code_symbol) => {
    // setExchange(exchange);
    navigate(`/invest/Equities/${co_code}`);
  };
  React.useEffect(() => {
    getAllCategory();
    getAllRecomStock();
  }, []);

  return (
    <>
      <div className="row">
        {loadingStock ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : recoStocksData?.length < 4 &&
          recoStocksData?.length !== 0 &&
          !isMobile ? (
          <div
            style={{
              display: "flex",
              gap: 10,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            {recoStocksData?.map((stock, index) => {
              return (
                <div key={index}>
                  <div
                    className="slide"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      selectedSearchItem(
                        stock?.cmots_code,
                        stock?.code_symbol
                        // stock?.exchange
                      )
                    }
                  >
                    <div
                      className="slidelogo"
                      style={{
                        width: 100,
                        height: 100,
                      }}
                    >
                      {stock?.compay_logo_url ? (
                        <img
                          src={stock?.compay_logo_url}
                          alt={stock?.company_code}
                          width={100}
                          height={100}
                          className="responsive"
                        />
                      ) : (
                        <BackgroundLetterAvatars name={stock?.company_code} />
                      )}
                    </div>
                    <div>
                      <Tooltip title={stock?.company_name}>
                        <p
                          style={{
                            textTransform: "uppercase",
                            fontSize: 13,
                            marginTop: 20,
                          }}
                        >
                          {stock?.company_code.length > 10
                            ? stock?.company_code.substring(0, 6) + "..."
                            : stock?.company_code}
                          <span
                            style={{
                              color:
                                stock?.stock_recommendation == "sell"
                                  ? "#df3c27"
                                  : "#45b6a7",
                            }}
                          >
                            ({stock?.stock_recommendation})
                          </span>
                        </p>
                      </Tooltip>
                      <div
                        className={
                          Number(stock?.change) < 0
                            ? "slidecont down"
                            : "slidecont"
                        }
                      >
                        <div className="price">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span>₹{stock?.Price ? stock?.Price : "NA"}-</span>
                            {stock?.Price ? (
                              <span className="priceup">
                                {" "}
                                ({Number(Math.abs(stock?.change)).toFixed(2)}
                                %)
                              </span>
                            ) : null}
                          </div>
                          <span>
                            Stop/Loss Price - ₹{stock?.stop_loss_price}
                          </span>
                          <br />
                          <span>Target Price - ₹{stock?.target_price}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : recoStocksData?.length >= 4 ? (
          <StockSlider
            recoStocksData={recoStocksData}
            selectedSearchItem={selectedSearchItem}
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={Nostock} />
            <h4>Happy to see you</h4>
            <p style={{ textAlign: "center" }}>
              We are working on it, Please come back later
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default AllStocks;
