import { SEO_URL } from "./constants";
import Subhashji from "../images/about/Subhash-Aggarwal.svg";
import maheshji from "../images/about/Mahesh-Gupta.svg";
import damodarji from "../images/about/Damodar-Aggarwal.svg";
import ajayji from "../images/about/Ajay-Garg.svg";
import anuragji from "../images/about/Anurag-Bansal.svg";
import himanshuji from "../images/about/Himanshu-Gupta.svg";
import pravinji from "../images/about/pravin-aggarwal.svg";
import reemamaam from "../images/about/reema-garg.svg";
import ayushji from "../images/about/Ayush-Aggarwal.svg";
import pranayji from "../images/about/Pranay-Aggarwal.svg";
import shurtimaam from "../images/about/shruti-aggarwal.svg";
import nidhimaam from "../images/about/Nidhi-Bansal.svg";

export const oldRoutes = [
  "/refer-and-earn",
  "/online-trading/overview",
  "/research/tools/algo-trader",
  "/research/research-overview",
  "/research/research-details/commodities-analysis",
  "/research/research-details/derivatives-analysis",
  "/reseatailsrch/research-de/equity-analysis",
  "/Research/Research-Details/Mutual-Fund-Analysis",
  "/research/new-letters",
  "/research/research-details/ipo-reports",
  "/webinars",
  "/partner-us",
  "/become-an-smc-distributor",
  "/research/research-details/currency-analysis",
  "/refer-and-earn?utm_source=direct&utm_medium=home%20page%20menu&utm_campaign=refer%20and%20earn",
  "/downloads",
  "/contact-us.aspx",
  "https://smctradeonline.com",
  "/market/ipo/current-ipo.aspx",
  "/pnb-smc-online-trading",
  "/iob-smc-online-trading",
  "/union-bank-smc-online-trading",
  "/kvb-smc-online-trading/kvb-smc-online-trading.aspx",
  "/groot-ujjijav-bank/groot-ujjijav-bank.aspx",
  "/nainital-bank/nainital-bank.aspx",
  "/online-trading/union-bank-of-india",
  "/online-trading/iob-online-trading",
  "/groot-ujjivan-bank",
  "/demat-account/tnc.aspx",
  "/iob-online-trading/iob-online-trading",
];

export const newRoutes = [
  "/invest/refer-earn?utm_source=direct&utm_medium=home%20page%20menu&utm_campaign=refer%20and%20earn",
  "/trade",
  "/trade/algo-trading-platform",
  "/research",
  "/research/commodities-analysis/70",
  "/research/derivative-analysis/114",
  "/research/equity-analysis/119",
  "/research/mutual-fund-analysis/116",
  "/research/wise-money/118",
  "/research/ipo-reports/43",
  "/learning-centre/webinar",
  "/partner-us/authorized-person",
  "/partner-us/become-distributor",
  "/research/currency-analysis/120",
  "/invest/refer-earn?utm_source=direct&utm_medium=home%20page%20menu&utm_campaign=refer%20and%20earn",
  "/contact-us/downloads",
  "/contact-us",
  "https://www.smctradeonline.com",
  "/invest/ipo",
  "https://old.smctradeonline.com/pnb-smc-online-trading",
  "https://old.smctradeonline.com/iob-smc-online-trading",
  "https://old.smctradeonline.com/union-bank-smc-online-trading",
  "https://old.smctradeonline.com/kvb-smc-online-trading/kvb-smc-online-trading.aspx",
  "https://old.smctradeonline.com/groot-ujjijav-bank/groot-ujjijav-bank.aspx",
  "https://old.smctradeonline.com/nainital-bank/nainital-bank.aspx",
  "https://old.smctradeonline.com/demat-account/tnc.aspx",
];

export const dataRankings = [
  {
    title: "(Annualised returns)",
    content:
      "Average of the yearly returns of a mutual fund over a given period.",
  },
  {
    title: "(Absolute Returns)",
    content: "The total return of a mutual fund over a given period.",
  },
];
export const dataExpense = [
  {
    title: "(Expense Ratio)",
    content:
      "A fee payable to a mutual fund house for managing your mutual fund investments. It is the total percentage of a company’s fund assets used for administrative, management, advertising, and other expenses.",
  },
  {
    title: "(Exit Load)",
    content:
      "A fee payable to a mutual fund house for exiting a fund (fully or partially) before the completion of a specific period from the date of investment.",
  },
  {
    title: "(Tax)",
    content:
      "A percentage of your capital gains payable to the government upon exiting your mutual fund investments. Taxation is categorized as long-term capital gains (LTCG) and short-term capital gains (STCG) depending on your holding period and the type of fund.",
  },
  {
    title: "(Stamp Duty)",
    content:
      "A form of tax payable for the purchase or sale of an asset or security",
    content1:
      "Check past data - As a user, I should be able to see the check past data CTA under the expense ratio, exit load, and tax section. On clicking the check past data, the user will see the past expense ratio and exit load tab",
  },
];

export const seoData = {
  referEarn: {
    image: "../../images/SMCNEWLOGO.webp",
    url: `${SEO_URL}/invest/refer-earn`,
    title: "Refer & Earn Demat Account - Earn upto ₹1000 | SMC Global Online",
    description:
      "Join exciting Refer & Earn Demat Account Programme. Start Referring your Friends & Earn Gift Vouchers upto ₹1000, Visit Now to know more at SMC Global Online.",
  },
  home: {
    image: "../../images/SMCNEWLOGO.webp",
    url: `${SEO_URL}/`,
    title: "Online Stock Market Trading in India - SMC Global Online",
    description:
      "SMC Global offers the best Online Share Trading platform in India. We provide top investment opportunities in Stocks, Foreign Exchange, Commodities, F&O, Mutual Funds, etc.",
  },
  invest: {
    image: "../../images/SMCNEWLOGO.webp",
    url: `${SEO_URL}/invest`,
    title:
      "Invest in Share Market - How to Invest in Stock Market - SMC Trade Online",
    description:
      "Invest in Share Market - Start investing in Stocks, IPOs and Mutual Funds and Fixed Income Options. Visit SMC Trade Online for the best Online investment in India.",
  },
  tradingHolidays: {
    image: "../../../images/SMCNEWLOGO.webp",
    url: `${SEO_URL}/invest/trading-holidays-2024`,
    title:
      "Stock Market Holidays - Trading Holidays 2024 - SMC Global Securities",
    description:
      "Find a complete list of share Market Holidays in 2024 along with trading holidays that fall on weekends as well.",
  },
  nseHolidays: {
    image: "../../../images/SMCNEWLOGO.webp",
    url: `${SEO_URL}/invest/nse-holiday-2024`,
    title:
      "NSE Holidays - NSE Holidays 2024 Complete List - SMC Global Securities",
    description:
      "NSE Holidays - View the complete NSE trading market holiday list for 2024 published by the stock exchange",
  },
  bseHolidays: {
    image: "../../../images/SMCNEWLOGO.webp",
    url: `${SEO_URL}/invest/bse-holiday-2024`,
    title:
      "BSE Holidays - BSE Holidays 2024 Complete List - SMC Global Securities",
    description:
      "BSE Holidays - View the complete BSE trading market holiday list for 2024 published by the stock exchange",
  },
  mcxHolidays: {
    image: "../../../images/SMCNEWLOGO.webp",
    url: `${SEO_URL}/invest/mcx-holiday-2024`,
    title: "MCX Holidays - MCX Holiday List 2024- SMC Global Securities",
    description:
      "MCX Holiday List - Find the list of MCX trading holidays in 2024 and plan your investing according to MCX Holidays. Here's the full list of MCX holidays.",
  },
  muhuratDates: {
    image: "../../../images/SMCNEWLOGO.webp",
    url: `${SEO_URL}/invest/muhurat-holiday-2024`,
    title:
      "Muhurat Trading - Muhurat Trading Time 2024 - SMC Global Securities",
    description:
      "Muhurat trading is a special trading session that takes place on the occasion of Diwali in India. Find BSE & NSE Diwali Muhurat Trading Timings for 2024.",
  },
  trade: {
    image: "../../../images/SMCNEWLOGO.webp",
    url: `${SEO_URL}/trade`,
    title:
      "Trade in Futures and Options – Get Stocks List of F&O Trading at SMC Global Securities",
    description:
      "Open Online Trading Account with India's leading Stock Broker SMC Global - Best Trading platform in India to Invest in Equity, Commodity, Currency, IPOs, Mutual Funds etc.",
  },
  algoTrading: {
    image: "../../../../images/SMCNEWLOGO.webp",
    url: `${SEO_URL}/trade/algo-trading-platform`,
    title: "Algo Trading Software | Best Algo Trading Platform in India - SMC",
    description:
      "Algo Trading Software - SMC Offers the best multi-asset, multi-currency, multi-exchange Algo Trading Platform to Invest & create algo trading strategies.",
  },
  research: {
    image: "../../images/SMCNEWLOGO.webp",
    url: `${SEO_URL}/research`,
    title: "Detailed Research Report of various companies BY SMC Global",
    description:
      "Research reports covering investment summary, Reasons to buy and not to buy, valuations, equity analysis, IPO Research, mutual fund research of various companies BY SMC Global",
  },
  webinar: {
    image: "../../images/SMCNEWLOGO.webp",
    url: `${SEO_URL}/learning-centre/webinar`,
    title: "Webinars - SMC Global Securities Ltd",
    description:
      "SMC offers live webinars where you get the chance to learn about the online investment by our specialists.",
  },
  distributor: {
    image: "../../../images/SMCNEWLOGO.webp",
    url: `${SEO_URL}/partner-us/become-distributor`,
    title:
      "Become an SMC Distributor - one platform for all financial products",
    description:
      "Become an SMC Distributor - one platform for all financial products",
  },
  partnerUs: {
    image: "../../images/SMCNEWLOGO.webp",
    url: `${SEO_URL}/partner-us/authorized-person`,
    title:
      "Become a SMC Authorized Person and start your growth journey with SMC Global Securities",
    description:
      "Become a SMC Authorized Person and start your growth journey with SMC Global Securities",
  },
  contactUs: {
    image: "../../images/SMCNEWLOGO.webp",
    url: `${SEO_URL}/contact-us`,
    title: "Contact Us - SMC Global",
    description:
      "Contact Us -  Visit Smctradeonline.com to Investment in stocks, currency, commodity, futures, options, mutual funds etc.",
  },
  openDemat: {
    image: "../../images/SMCNEWLOGO.webp",
    url: `${SEO_URL}/demat-account`,
    title: "Open a Free Demat Account - SMC Global Securities",
    description: "Open a Free Demat Account - SMC Global Securities",
  },
};

export const importantLinks = [
  {
    doc_name: "SEBI",
    doc_file: "https://scores.gov.in/scores/Welcome.html",
  },
  {
    doc_name: "SCORES",
    doc_file: "https://scores.sebi.gov.in/",
  },
  {
    doc_name: "NSE",
    doc_file: "https://www.nseindia.com/",
  },
  {
    doc_name: "BSE",
    doc_file: "https://www.bseindia.com/",
  },
  {
    doc_name: "MCX",
    doc_file: "https://www.mcxindia.com/Investor-Services",
  },
  {
    doc_name: "NCDEX",
    doc_file: "https://ncdex.com/grievances",
  },
  {
    doc_name: "CDSL",
    doc_file: "https://www.cdslindia.com/Footer/grievances.aspx",
    https: "www.epass.nsdl.com/complaints/websitecomplaints.aspx",
  },
  {
    doc_name: "MSEI",
    doc_file: "https://www.msei.in/Investors/Introduction",
  },
  {
    doc_name: "NSDL",
    doc_file: "https://nsdl.co.in",
  },
  {
    doc_name: "RBI",
    doc_file: "https://www.rbi.org.in/home.aspx",
  },
  {
    doc_name: "IRDA",
    doc_file: "http://www.irdaindia.org",
  },
  {
    doc_name: "AMFI",
    doc_file: "https://www.amfiindia.com",
  },
  {
    doc_name: "FMC",
    doc_file: "http://www.fmc.gov.in",
  },
  {
    doc_name: "Ministry of Finance",
    doc_file: "https://www.finmin.nic.in",
  },
  {
    doc_name: "Government of India",
    doc_file: "https://www.india.gov.in",
  },
  {
    doc_name: "Investor Grievances for DP",
    doc_file: "mailto:dp@smcindiaonline.com",
  },
  {
    doc_name: "Investor Awareness",
    doc_file: "http://www.old.smctradeonline.com/Static/careers.aspx",
  },
  {
    doc_name: "Investor Complaint Form BSE",
    doc_file: "https://www.bseindia.com/investors/Arbitrage.aspx?expandable=3",
  },
  {
    doc_name: "Enhancement of IDeAS and e – voting integration",
    doc_file: "https://eservices.nsdl.com",
  },
  {
    doc_name: "ODR",
    doc_file: "https://smartodr.in/login",
  },
  {
    doc_name: "Advisory for Investor",
    doc_file: "https://www.smctradeonline.com/advisory-for-investors",
  },
  {
    doc_name: "Investor Education Material",
    doc_file:
      "https://www.nseindia.com/invest/first-time-investor-fraud-dos-and-donts",
  },
  {
    doc_name: "Client Collateral Details",
    doc_file:
      "https://investorhelpline.nseindia.com/ClientCollateral/welcomeCLUser",
  },
];

export const memberDetails = [
  {
    img: Subhashji,
    name: "Mr. Subhash Chandra Aggarwal",
    post: "Chairman & Managing Director",
    company: "SMC Group",
    bio: "Mr. Subhash C Aggarwal is the Co-Founder of SMC Group and has diverse experience in the financial services industry in India. With over 29 years of experience in securities market and financial services, he has been the key architect of SMC’s growth.",
  },
  {
    img: maheshji,
    name: "Mr. Mahesh Chand Gupta",
    post: "Vice Chairman & Managing Director",
    company: "SMC Group",
    bio: "Mr. Mahesh C Gupta is the Vice Chairman & Managing Director of SMC Global Securities Limited. He is Co-Founder and promoter of SMC Group. With his vast experience, in depth knowledge  and strong analytical skill, he efficiently assures flawless operations of the Group. ",
  },
  {
    img: damodarji,
    name: "Mr. Damodar Krishan Aggarwal",
    post: "CMD",
    company:
      "SMC Capitals ltd. and SMC Investments & Advisors Ltd., Chairman & Director-SMC Real Estate Advisors Private Limited and SMC Comtrade Limited",
    bio: "Mr. Damodar K Aggarwal is a promoter and one of the key architects of success of the SMC Group. He has recently been conferred upon with ‘The Distinguished Entrepreneurship Award’- by one of the apex business chambers of India “PHD Chamber of Commerce”.",
  },
  {
    img: ajayji,
    name: "Mr. Ajay Garg",
    post: "Director & CEO",
    company:
      "SMC Global Securities Ltd., SMC Global IFSC Private Limited, Director – Moneywise Financial Services Private Limited",
    bio: "Mr. Ajay Garg is a fellow member of the Institute of Chartered Accountants of India (ICAI) & a rank holder having wide and rich experience of more than 22 years in Securities, Commodities & Currency markets. He heads the Broking & Clearing Division.",
  },
  {
    img: anuragji,
    name: "Mr. Anurag Bansal",
    post: "Whole Time Director",
    company: "SMC Global Securities Ltd.",
    bio: "Mr. Anurag Bansal, aged 42 years, is a rank holder and fellow member of the Institute of Chartered Accountants of India (ICAI) and a member of Institute of Cost and Works Accountants of India (ICWAI). He possesses extensive experience of over 20 years in Capital Markets.",
  },
  {
    img: himanshuji,
    name: "Mr. Himanshu Gupta",
    post: "Chairman & CEO",
    company: "Moneywise Financial Services Pvt. Ltd.",
    bio: "Mr. Himanshu Gupta is a young, dynamic and versatile personality and he plays a key and active role in core strategic business decisions of the SMC Group. He began his career with SMC in 2011 as Senior Vice President (Operations).",
  },
  {
    img: pravinji,
    name: "Mr. Pravin Agarwal",
    post: "Whole Time Director",
    company: "SMC Insurance Brokers Pvt. Ltd.",
    bio: "Mr. Pravin Agarwal is currently acting as a whole time director of SMC Insurance Brokers Private Limited. Our subsidiary company is actively involved in the development of our insurance broking business. With more than a decade of work experience.",
  },
  {
    img: reemamaam,
    name: "Mrs. Reema Garg",
    post: "Group CHRO & Director",
    company: "SMC Investments & Advisors Ltd., Moneywise Finvest Limited.",
    bio: "Mrs. Reema Garg possesses excellent communication and interpersonal skills and plays a key and active role in strategic planning & brand building for the organization. She is self-motivated woman having a professional approach emphasizing on the core values of the organization.",
  },
  {
    img: ayushji,
    name: "Mr. Ayush Aggarwal",
    post: "CIO (Chief Investment Officer)",
    company:
      "SMC Real Estate Advisors Pvt. Ltd., Director - SMC Real Estate Advisors Private Limited.",
    bio: "Mr. Ayush Aggarwal is a young dynamic business leader. He is an MBA (PGP-FMB) from SP Jain Institute of Management and Research, Mumbai, and a graduate from Delhi University. He has a great understanding and an in-depth knowledge of financial Market.",
  },
  {
    img: pranayji,
    name: "Mr. Pranay Aggarwal",
    post: "Whole Time Director",
    company:
      "SMC Comtrade Limited and Director- SMC Real Estate Advisors Private Limited.",
    bio: "Mr. Pranay Aggarwal is involved in the strategic planning of SMC Group and he has been instrumental in the launch of ‘SMC Gold’ – a dedicated HNI clients’ desk providing premium and top of the line services. Additionally, he is involved in the technological upgradation of the SMC Group.",
  },
  {
    img: shurtimaam,
    name: "Mrs. Shruti Aggarwal",
    post: "Executive Director",
    company: "SMC Global Securities Limited",
    bio: "Mrs. Shruti Aggarwal is an Executive Director of SMC Global and is currently leading the Technology, Corporate Finance team and GIFT City Operations in the SMC Group. She is an MBA from London Business School, fellow member of ICAI, and has completed CFA from CFA Institute, USA. She has a rich experience of 15+ years in various industries.",
  },
  {
    img: nidhimaam,
    name: "Mrs. Nidhi Bansal",
    post: "Regional Director - West",
    company: "SMC Global Securities Limited",
    bio: "Ms. Nidhi Bansal is Regional Director - West. She is post graduate in commerce and a fellow member of Institute of Chartered Accountants of India (ICAI). She is handling the business development, operations and human resource section of the Western Zone.",
  },
];
